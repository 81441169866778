import React, { Component } from "react";
import GalleryFormData from "./GalleryFormData";
import GalleryFormStart from "./GalleryFormStart";

import GalleryFormImage from "./GalleryFormImage";
import Final from "./Final";

import firebase from "../components/firebase"

const db = firebase.firestore();
var today = new Date();
var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

class GalleryForm extends Component {
  state = {
    step: 1,
    userName: "",
    description: "",
    title: "",
    url: "",
    date:"",
  };


  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  };
  // go back to the previous step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  handleSubmit = e => {
    if (typeof window !== "undefined") {
      var g4cImagePath = localStorage.getItem('G4C_IMAGE_PATH_GALLERY');
    }
    e.preventDefault()
    db.collection('gallery').add({
      gallery_userName: this.state.userName,
      gallery_description: this.state.description,
      gallery_title: this.state.title,
      gallery_url: g4cImagePath,
      date: date,
      approved:false,


    });

    this.setState({
    step: 1,
    userName: "",
    description: "",
    title: "",
    url: "",
    date: "",

    });
  }

  //Handle fields change
  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };
  render() {
    const { step } = this.state;
    const { userName, description, title, url  } = this.state;
    const values = { userName, description, title, url };
 
    switch (step) {
      case 1:
        return (

          <GalleryFormStart
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      // case 2:
      //   return (

      //     <GalleryFormData
      //       nextStep={this.nextStep}
      //       prevStep={this.prevStep}
      //       handleChange={this.handleChange}
      //       values={values}
      //     />
      //   );
      case 2:
        return (
          <GalleryFormImage
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
        case 3:
          return (
            <Final
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
            handleSubmit={this.handleSubmit}
            />
          );
        default:
        break;
    }


  }
}

export default GalleryForm;
