import React, { useState } from 'react';
import ImageGrid from '../components/ImageGrid';
import Modal from '../components/Modal';
import GalleryForm from '../components/GalleryForm';
import NavBar from "../components/navbar";
import Helmet from 'react-helmet'


function Gallery() {
  const [selectedImg, setSelectedImg] = useState(null);

  return (
  <>
        <Helmet>
          <body class='gallery_page' />
        </Helmet>
        <NavBar></NavBar>
        <GalleryForm />
        <ImageGrid setSelectedImg={setSelectedImg} />
        {selectedImg && (
          <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} />
        )}
  </>
  );
}

export default Gallery;