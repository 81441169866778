import React, { Component } from "react";


class GalleryFormStart extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, handleChange } = this.props;
    return (
      <>
    <div className="create_container">
              <a className="create_button"
                onClick={this.continue}
              > Upload A Photo</a>
     </div>
    </>
    );
  }
}

export default GalleryFormStart;
