import React, { Component } from "react";
import {storage} from "../components/firebase";
import { Link } from "gatsby";
import Icon from '@material-ui/core/Icon';
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import RaisedButton from "material-ui/RaisedButton";
import "../components/Layout/layout.css";

class GalleryFormImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      url: "",
      progress: 0
    };
  }
  continue = e => {
    e.preventDefault();
    let detectInput = document.querySelector('#grab_img_url');
    var parser = document.createElement('a');
    console.log(detectInput.src)
    parser.href = detectInput.src;
    let oldPath = parser.pathname;
    let jpegUpdate = oldPath.replace(".jpeg", "_800x800.jpeg");
    let jpgUpdate = jpegUpdate.replace(".jpg", "_800x800.jpg");
    let pngUpdate = jpgUpdate.replace(".png", "_800x800.png");
    const newPath = pngUpdate.replace("images%2F", "images%2Fresized%2F");
    
    const newURL =  parser.protocol + "//" + parser.hostname + newPath + "?alt=media";
    
    const mp4URL = parser.protocol + "//" + parser.hostname + pngUpdate + "?alt=media";

    localStorage.setItem('G4C_IMAGE_PATH_GALLERY', "");
    console.log("G4C_IMAGE_PATH_GALLERY " + newURL)
    localStorage.setItem('G4C_IMAGE_PATH_GALLERY', newURL);
    localStorage.setItem('G4C_VIDEO_PATH_GALLERY', "");
    console.log("G4C_VIDEO_PATH_GALLERY " + mp4URL)
    localStorage.setItem('G4C_VIDEO_PATH_GALLERY', mp4URL);
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };
  handleChange = e => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      this.setState(() => ({ image }));
    }
    let hide_input= document.querySelector('#hide_input');
    let uploadButton = document.querySelector('#upload_Button');
    hide_input.classList.add("hide");
    uploadButton.classList.add("show");

  };


  handleUpload = () => {
    let target_image = document.querySelector('#grab_img_url');
    target_image.classList.remove('hide')

    let uploadButton = document.querySelector('#upload_Button');
    uploadButton.classList.add("hide");
    let allowContinue = document.querySelector('#allow_continue');
    allowContinue.classList.add("show");

    const { image, handleChange } = this.state;
    const uploadTask = storage.ref(`images/${image.name}`).put(image);
    uploadTask.on(
      "state_changed",
      snapshot => {
        // progress function ...
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress });
      },
      error => {
        // Error function ...
        console.log(error);
      },
      () => {
        // complete function ...
        storage
          .ref("images")
          .child(image.name)
          .getDownloadURL()
          .then(url => {
            this.setState({ url });
          });
      }
    );
  };
  render() {
    const { values } = this.props;

    return (
      <div className="fixed_form_wrapper">
        <div className="fixed_form">
        <span>Upload Progress: {this.state.progress}%</span>

        <div className="progress">
          <progress value={this.state.progress} max="100" className="progress" />
        </div>
        <div className="file-field input-field">
          <div className="btn">
            <input type="file" onChange={this.handleChange} value={values.url} id="hide_input"/>
          </div>
        </div>
        <button
          onClick={this.handleUpload}
          className="upload_Button"
          id="upload_Button"
        >
          Upload
        </button>
        <video loop="true" className="hide">
          <source src="https://intersport-assets.s3.amazonaws.com/Mpeg4.mp4"  type="video/mp4" /> 
          
        </video>
        <img
          id="grab_img_url"
          src={this.state.url}
          alt="Preview Image"
          width="400"
          className="hide"
        />
        <br />
        <MuiThemeProvider>
        <RaisedButton
                label="Back"
                primary={false}
                onClick={this.back}
                
              />
              <span id="allow_continue">
<RaisedButton
                label="Continue"
                primary={true}
                onClick={this.continue}
                
                
              />
</span>
              
              </MuiThemeProvider>
        <br />
        
      </div>
      </div>
    );
  }
}



export default GalleryFormImage;