import React from 'react';
import useFirestore from '../hooks/UseFirestore';
import { StyledImageGrid } from '../styles/ImageGrid.sc';

function ImageGrid({ setSelectedImg }) {
  const { docs } = useFirestore('gallery');
  console.log(docs);
  return (
    <StyledImageGrid>
      {docs &&
        docs.map((doc) => {
          if (doc.approved){
          return (
            <div
              className="img-wrap"
              key={doc.id}
              onClick={() => setSelectedImg(doc.gallery_url)}
            >
              <img className="override_img" src={doc.gallery_url} alt="pic" data-user={doc.gallery_userName} data-description={doc.gallery_description} data-title={doc.gallery_title} />
              
            </div>
          );
          }
        })}
    </StyledImageGrid>
  );
}

export default ImageGrid;